<template>
  <cui-acl  :roles="['cms']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span class="mr-3">Innlegg</span>
        <a-button style="float: right;" @click="$router.push({ path: `/cms/new-post` })">
          {{ $t('general.new') }} Innlegg
        </a-button>
      </h5>
    </div>
    <a-table :columns="columns" :dataSource="data" @change="onChange">
      <div
        slot="filterDropdown"
        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="c => (searchInput = c)"
          :placeholder="`Søk ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block;"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          Søk
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
          Reset
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
        <template slot="action" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
          </template>
          <a slot="action" @click="() => aboutUser(record.id)"><i class="fe fe-edit"></i></a>
        </template>
    </a-table>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import moment from 'moment'
import { mapState } from 'vuex'

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter)
}

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      data: [],
      columns: [
        {
          title: 'Tittel',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Skrevet av',
          dataIndex: 'author',
          key: 'author',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Dato',
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'id',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods: {
    moment,
    onChange,
    aboutUser(id) {
      this.$router.push({ path: `/cms/edit-post/${id}` })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
  mounted() {
    // create a search bar on top to filter any value for user const res = arr.filter(obj => Object.values(obj).some(val => val.includes(search)));
    firebase.firestore().collection('news').orderBy('date', 'desc').get()
      .then(snapshot => {
        let i = 0
        snapshot.forEach(doc => {
          i += 1
          this.data.push({
            key: i,
            id: doc.id,
            date: String(moment(doc.data().date).calendar()),
            author: doc.data().author.name,
            title: doc.data().title,
          })
        })
      })
  },
}
</script>
