var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cui-acl',{attrs:{"roles":['cms'],"redirect":""}},[_c('div',{staticClass:"kit__utils__heading"},[_c('h5',[_c('span',{staticClass:"mr-3"},[_vm._v("Innlegg")]),_c('a-button',{staticStyle:{"float":"right"},on:{"click":function($event){return _vm.$router.push({ path: "/cms/new-post" })}}},[_vm._v(" "+_vm._s(_vm.$t('general.new'))+" Innlegg ")])],1)]),_c('a-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.data},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"filterDropdown",fn:function(ref){
var setSelectedKeys = ref.setSelectedKeys;
var selectedKeys = ref.selectedKeys;
var confirm = ref.confirm;
var clearFilters = ref.clearFilters;
var column = ref.column;
return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:(function (c) { return (_vm.searchInput = c); }),expression:"c => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":("Søk " + (column.title)),"value":selectedKeys[0]},on:{"change":function (e) { return setSelectedKeys(e.target.value ? [e.target.value] : []); },"pressEnter":function () { return _vm.handleSearch(selectedKeys, confirm, column.dataIndex); }}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":function () { return _vm.handleSearch(selectedKeys, confirm, column.dataIndex); }}},[_vm._v(" Søk ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":function () { return _vm.handleReset(clearFilters); }}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"action",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
              .toString()
              .split(new RegExp(("(?<=" + _vm.searchText + ")|(?=" + _vm.searchText + ")"), 'i'))),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):void 0,_c('a',{attrs:{"slot":"action"},on:{"click":function () { return _vm.aboutUser(record.id); }},slot:"action"},[_c('i',{staticClass:"fe fe-edit"})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }